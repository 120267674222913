body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    background: #ebedf2;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

h1 {
    color: #ffd700;
    font-size: 50px;
    font-weight: 900;
    margin: 40vh;
    text-align: center;
}

.outline {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;
}

h2 {
    padding: 0 10px;
    font-size: 28px;
}

.intro {
    max-height: 100vh;
    overflow: hidden;
    position: relative;
}
a {
    text-decoration: none;
}

/* buttons */
.second {
    color: #000;
    box-shadow: 0 0 40px 40px #ffd700 inset, 0 0 0 0 #ffd700;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}
.second:hover {
    box-shadow: 0 0 10px 0 #ffd700 inset, 0 0 10px 4px #ffd700;
    /* color: #fff; */
    outline: 0;
}

.third {
    color: #000;
    box-shadow: 0 0 40px 40px #ffd700 inset, 0 0 0 0 #ffd700;
    -webkit-transition: all 150ms ease-in-out;
    transition: all 150ms ease-in-out;
}
.third:hover {
    box-shadow: 0 0 10px 0 #ffd700 inset, 0 0 10px 4px #ffd700;
    color: #fff;
    outline: 0;
}
/* end of buttons */

/* .intro:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
} */

.video {
    position: relative;
    padding-bottom: 56.25%;
    z-index: 1;
}

.video_media {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.overlay {
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
}

.intro {
    max-height: 100vh;
    overflow: hidden;
    position: relative;
}

.video_media img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* .intro_img {
    max-height: 23vh;
    overflow: hidden;
    position: relative;
}

.overlay_img {
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
} */

.navbar_container {
    display: flex;
    flex-direction: column;
}

.logo {
    cursor: pointer;
}

.logo img {
    height: 50px;
    width: auto;
    margin: 0 0 0 20px;
}

.navbar_menu_container {
    width: 200px;
    height: 70px;
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #ffd700;
}

.navbar_menu_container a {
    text-decoration: none;
    color: #ffd700;
}

.navbar_wrapper {
    width: 100%;
    height: 60px;
    margin: 0;
    display: flex;

    align-items: center;
    text-align: center;

    flex-direction: row;
    justify-content: space-between;
}

.contacts_container {
    width: 250px;
    margin-right: 20px;
    margin-left: auto;
    height: auto;
    display: flex;
    flex-direction: column;
}

.contacts_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 240px;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000;
}

.phone_wrapper {
    display: flex;
    width: 250px;
    height: auto;
    margin-right: 20px;
    margin-left: auto;
    align-items: center;
}

.phone_wrapper img {
    width: 40px;
}

.phone_wrapper a {
    font-size: 20px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    color: #ffd700;
    margin-left: 20px;
    border-bottom: 1px solid #ffd700;
}

.social_media_wrapper {
    width: 100%;
    height: auto;
    display: flex;
}

.social_media {
    display: flex;
    width: 250px;
    margin-right: 0;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-end;
}

.social_media a {
    width: auto;
    height: auto;
    padding-right: 10px;
}

.social_media img {
    /* padding-right: 10px; */
    width: 40px;
    cursor: pointer;
}

.social_media img:last-child {
    padding-right: 0;
}

.sale_link {
    cursor: pointer;
    font-size: 25px;
    font-weight: 400;
}

/* Advantages block */
.advantages_container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.advantages {
    width: 1100px;
    height: auto;
    text-align: center;
    /* background: #fff; */
}

.advantages_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    height: auto;
    width: auto;
    /* border: 1px solid red; */
}

.advantage {
    width: 325px;
    height: 200px;
    padding: 20px 10px;
    background: #fff;
    /* border: 1px solid black; */
    margin-bottom: 20px;
    box-shadow: 10px 10px 5px lightgrey;
}
.advantages_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 240px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    margin: 0 auto;
    color: #000;
}

/* .advantages_btn:hover {
    transform: scale(1.1);
    transition: 1s;
}

.advantages_btn:not(:hover) {
    transition: 1s;
} */
/* End of Advantages block*/

/* Social media vertical */
.social_media_vertical_wrapper {
    position: sticky;
    top: 0px;
    z-index: 999;
    right: 0;
    width: 40px;
    height: auto;
    /* display: flex; */
    /* justify-content: right; */
}

.social_media_vertical {
    display: flex;
    flex-direction: column;
    width: 40px;
}

.social_media_vertical a {
    width: auto;
    height: auto;
}

.social_media_vertical img {
    width: 40px;
    cursor: pointer;
}
/*  */

/*invest Offers */
.investment_offers_container {
    width: 100%;
    margin-top: 20px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    flex-direction: column;
}

.investment_offers_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1100px;
    height: auto;
    /* border: 1px solid black; */
    text-align: center;
}
/* end of invest offers */

.sale_objects_container {
    width: 100%;
    margin-top: 20px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sale_objects_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1100px;
    height: auto;
    /* border: 1px solid black; */
    text-align: center;
}

.sale {
    font-size: 30px;
    font-weight: 500;
    padding: 0 10px;
}

.rent_objects_container {
    width: 100%;
    /* text-align: center; */
    display: flex;
    align-items: center;
    flex-direction: column;
}

.rent_objects_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 1100px;
    height: auto;
    /* border: 1px solid black; */
    text-align: center;
}

.card {
    width: 380px;
    height: 555px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    margin-top: 35px;
    /* box-shadow: 10px 10px 5px #ffd700; */
    cursor: pointer;
    /* border-radius: 10px; */
}

.card:not(:hover) {
    transition: 0.9s;
}

.card:hover {
    opacity: 0.7;
    transition: 0.9s;
    box-shadow: 10px 10px 5px #ffd700;
}

.card img {
    width: 380px;
    height: 240px;
    /* border-radius: 10px; */
    object-fit: cover;
}

.house_name {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 700;
}

.card table {
    /* background: #d2f2ef; */
    margin-top: 15px;
    border-spacing: 25px;
}

.card_price {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffd700;
}

.price {
    height: auto;
    font-size: 20px;
    font-weight: 400;
    /* background-color: #ffd700; */
}

.price span {
    font-weight: 900;
    font-size: 29px;
    /* background-color: #ffd700; */
}

.currency img {
    width: auto;
    margin-left: 10px;
    height: 25px;
}

.link_to {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 300px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 20px;
    background: transparent;
    border: none;
}

/* .link_to:hover {
    transform: scale(1.1);
    transition: 1s;
}

.link_to:not(:hover) {
    transition: 1s;
} */
/*  */
.object_navbar_container {
    width: 100%;
    min-height: 28vh;
    flex-direction: column;
    background: #24262b;
/* background-image: url(../public/flag.jpg);
background-size: cover; */
}
/*  */
/* об объекте */
.object_container {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.object_wrapper {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* align-items: center; */
    width: auto;
    height: auto;
    /* border: 1px solid black; */
    text-align: center;
    background: #fff;
    box-shadow: 10px 10px 5px lightgrey;
}

.slider_styles {
    width: 800px;
    height: 550px;
    margin: 0 auto;
}

.slider_preview_image {
    height: 40px;
    width: 60px;
    margin-top: 5px;
}

.object_housename {
    font-size: 40px;
    font-weight: 900;
}

/* .mainSlide:hover {
    transform: scale(1.18);
    transition: 1.5s;
} */

/* .mainSlide:not(:hover) {
    transition: 1.5s;
} */

.object_info {
    margin-top: 20px;
    display: flex;
    /* flex-direction: row; */
    width: auto;
    /* border: 1px solid black; */
    /* background: #fff; */
}

.object_description {
    /* margin-left: 10px; */
    justify-content: center;
    /* text-align: center; */
    width: 550px;
    /* border: 1px solid black; */
}

.house_info_container {
    text-align: left;
    margin: 0 auto;
    width: auto;
    height: auto;
    /* margin-left: 20px; */
    white-space: pre-line;
    font-size: 18px;
    /* border: 1px solid greenyellow; */
}

.object_infrastructure {
    text-align: center;
    width: 550px;
    height: auto;
    white-space: pre-line;
    /* margin-left: 50px; */
    margin-top: 50px;
    font-size: 18px;
    /* border: 1px solid grey; */
}

.object_map {
    width: 550px;
    height: auto;
    min-height: 550px;
    /* border: 1px solid red; */
}

/* .more_info {
    width: 100%;
    height: auto;
} */

.more_info_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 300px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #000;
}

/* .more_info_btn:hover {
    transform: scale(1.1);
    transition: 1s;
}

.more_info_btn:not(:hover) {
    transition: 1s;
} */

/* конец инф по объекту */

.catalog_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
}
/* форма фильтрации */
.filters_container form {
    width: 290px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    color: #000;
}

.filters_container {
    margin-top: 40px;
    max-width: 1100px;
    height: auto;
    display: flex;
    flex-direction: row;
}

.filter_description {
    width: 150px;
    height: 30px;
    font-weight: 500;
}
.filter_item_wrapper {
    display: flex;
    align-items: center;
}

.filter_item_wrapper select {
    color: #000;
}

.filters_container input {
    width: 300px;
    height: 30px;
    border: none;
    box-shadow: 10px 5px 5px lightgrey;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 20px;
}

.filters_container select {
    width: 150px;
    height: 30px;
    border: none;
    box-shadow: 10px 5px 5px lightgrey;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 10px;
}

/* .search_btn {
    background-color: #ffd700;
    font-size: 20px !important;
    font-weight: 500 !important;
} */

.search_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 300px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 20px;
    background: #ffd700;
    border: none;
    cursor: pointer;
    color: #000;
}

.search_btn:hover {
    transform: scale(1.1);
    transition: 1s;
}

.search_btn:not(:hover) {
    transition: 1s;
}
/* конец формы фильтрации */

/*Модальное окно*/
.modal {
    /* box-sizing:border-box; */
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal_content {
    padding: 20px;
    border: 4px solid #ffd700;
    border-radius: 12px;
    background-color: white;
    width: 350px;
    /* transform: scale(0.5);
    transition: 0.4s all; */
}

/* .modal_content .active {
    transform: scale(1);
} */

/*  */

/* форма обратной связи */

.search_form_container {
    /* height: 540px; */
    height: 640px;
    width: 380px;
    border: 4px solid #ffd700;
    text-align: center;
    margin: 20px auto;
    background: light grey;
    color: white;
}

.search_form {
    width: auto;
    height: 640px;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.search_form_input {
    height: 30px;
    width: 300px;
    margin: 0 auto;
    border: none;
    cursor: text;
    margin-bottom: 15px;
    font-size: 16px;
}

.search_form_input_last {
    height: 30px;
    width: 300px;
    margin: 0 auto;
    border: none;
    cursor: text;
    font-size: 16px;
}

.search_form_radiobuttons_container {
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
}
.search_form_container_textarea {
    resize: none;
    width: 300px;
    margin: 0 auto;
    border: none;
    cursor: text;
    margin-top: 25px;
    margin-bottom: 0px;
    font-size: 16px;
}

.search_form_container_btn {
    /* margin-top: 10px; */
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 300px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 20px;
    background: transparent;
    border: none;
    color: black;
    cursor: pointer;
}

/* .search_form_container_btn:hover {
    transform: scale(1.1);
    transition: 1s;
}

.search_form_container_btn:not(:hover) {
    transition: 1s;
} */
/* конец формы обратной связи */

/* Footer */
.footer {
    background: #24262b;
    padding: 30px 0;
}

.footer_container {
    max-width: 1100px;
    margin: auto;
    /* background: red; */
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.footer_col {
    width: 156px;
    height: 170px;
    padding: 0 15px;
    /* border: 1px solid green; */
}

.footer img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.footer_col h4 {
    font-size: 18px;
    color: #fff;
    /* text-transform: capitalize; */
    margin-bottom: 25px;
    font-weight: 500;
    position: relative;
}

.footer_col h4::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    background: #ffd700;
    height: 2px;
    box-sizing: border-box;
    width: 50px;
}
.footer_col ul li:not(:last-child) {
    margin-bottom: 10px;
}

.footer_col ul li a {
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
}

.footer_col ul li a:hover {
    color: #ffffff;
    padding-left: 8px;
}

.footer_col ul li div {
    font-size: 16px;
    /* text-transform: capitalize; */
    color: #ffffff;
    /* text-decoration: none; */
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    /* transition: all 0.3s ease; */
}

.footer_phone {
    margin-bottom: 10px;
}

.footer_phone a {
    text-decoration: none;
    color: #ffd700;
}

@media (max-width: 767px) {
    .footer_col {
        width: 50%;
        margin-bottom: 30px;
    }
}
@media (max-width: 574px) {
    .footer_col {
        width: 100%;
    }
}

/* End of Footer */

/* ModalPhoto */
.overlay_modal_photo {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.541);
    display: flex;
    align-items: center;
}
.overlay_modal_photo img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay_modal_photo > span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
}
.overlay-arrows_left {
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 999;
}

.overlay-arrows_left svg {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.overlay-arrows_right svg {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.overlay-arrows_right {
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;
}
/* end of Modalphoto */

@media screen and (max-width: 1300px) {
    h1 {
        margin: 30vh;
    }
}

@media screen and (max-width: 1100px) {
    h1 {
        margin: 10vh;
        font-size: 40px;
    }

    .object_info {
        flex-direction: column;
    }

    .object_map {
        height: 550px;
    }
}

@media screen and (max-width: 860px) {
    h1 {
        margin: 50px;
        font-size: 30px;
    }

    /*форма фильтрации */
    .filters_container form {
        width: 290px;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }

    .filters_container {
        margin-top: 40px;
        max-width: 1100px;
        height: auto;
        display: flex;
        flex-direction: row;
    }

    .filter_description {
        width: 150px;
        height: 30px;
        font-weight: 500;
    }
    .filter_item_wrapper {
        display: flex;
        align-items: center;
    }

    .filters_container input {
        width: 300px;
        height: 30px;
        border: none;
        box-shadow: 10px 5px 5px lightgrey;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        margin-bottom: 20px;
    }

    .filters_container select {
        width: 150px;
        height: 30px;
        border: none;
        box-shadow: 10px 5px 5px lightgrey;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;
        margin-bottom: 10px;
    }

    .search_btn {
        background-color: #ffd700;
        font-size: 20px !important;
        font-weight: 500 !important;
    }
    /*конец формы фильтрации*/
}

@media screen and (max-width: 850px) {
    h1 {
        margin: 15px;
        font-size: 25px;
    }

    h2 {
        font-size: 20px;
    }

    .logo img {
        height: 30px;
        margin-top: 0px;
        margin-right: 20px;
    }

    .sale_link {
        cursor: pointer;
        font-size: 20px;
        font-weight: 400;
    }

    .contacts_container {
        width: 225px;
    }

    .contacts_btn {
        width: 213px;
        font-size: 12px;
    }

    .phone_wrapper img {
        width: 30px;
    }

    .phone_wrapper a {
        font-size: 18px;
    }

    .social_media {
        margin-right: 3px;
    }

    .social_media img {
        width: 30px;
    }

    .sale_objects_wrapper {
        max-width: 750px;
    }
    .sale_objects_wrapper {
        max-width: 750px;
    }

    .slider_styles {
        width: 550px;
        height: 343px;
    }

    .slider_preview_image {
        height: 20px;
        width: 35px;
        margin-top: 5px;
    }

    .object_description {
        width: 450px;
    }

    .house_info_container {
        font-size: 16px;
    }

    .object_wrapper {
        margin-top: 40px;
    }

    .object_infrastructure {
        width: 450px;
        font-size: 16px;
    }

    .object_map {
        width: 450px;
        height: 550px;
    }
}

@media screen and (max-width: 500px) {
    h1 {
        margin: 0;
        font-size: 19px;
    }

    h2 {
        font-size: 19px;
    }
    .navbar_wrapper {
        /* flex-direction: column; */
        height: 60px;
    }

    .navbar_menu_container {
        width: 100%;
        height: 30px;
        margin-right: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        color: #ffd700;
    }

    .logo {
        margin-left: 20px;
        /* margin-top: 4px; */
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 30px;
    }

    .logo img {
        height: 25px;
        margin: 0;
    }

    .navbar_menu_container {
        width: 150px;
        margin-right: 0px;
        margin-left: auto;
    }

    .sale {
        text-align: center;
    }

    .sale_link {
        width: 64px;
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
    }

    .contacts_container {
        width: 195px;
        margin-right: 12px;
        /* margin-top: 20px; */
    }

    .phone_wrapper {
        width: auto;
        margin-right: 14px;
        margin-left: auto;
    }

    .phone_wrapper img {
        width: 20px;
    }

    .phone_wrapper a {
        font-size: 13px;
    }

    .contacts_btn {
        display: none;
        /* width: 157px;
        margin-left: 28px;
        font-size: 12px; */
    }

    .social_media {
        margin-right: 3px;
    }

    .social_media img {
        width: 25px;
    }

    .card {
        width: 325px;
        height: 467px;
    }

    .card table {
        /* background: #d2f2ef; */
        margin-top: 15px;
        border-spacing: 10px;
    }

    .card img {
        width: 325px;
        height: 180px;
    }

    .house_name {
        margin-top: 10px;
        font-size: 18px;
    }

    /* table {
        margin-top: 10px;
        border-spacing: 10px;
    } */

    .card_price {
        margin-top: 30px;
    }

    .price {
        height: auto;
        font-size: 15px;
        font-weight: 400;
    }

    .price span {
        font-size: 20px;
    }

    .currency img {
        width: auto;
        margin-left: 10px;
        height: 20px;
    }
    /*форма обратной связи  */
    .search_form_container {
        height: 640px;
        width: 325px;
    }

    .search_form_input {
        height: 30px;
        width: 250px;
        margin-bottom: 25px;
        font-size: 16px;
    }

    .search_form_input_last {
        height: 30px;
        width: 250px;
        margin: 0 auto;
        border: none;
        cursor: text;
        font-size: 16px;
    }

    .search_form_container-header {
        margin-top: 10px;
        margin: 10px auto 10px;
        width: 250px;
    }

    .search_form_radiobuttons_container {
        width: 300px;
        margin-left: 12px;
    }
    .search_form_container_textarea {
        width: 250px;
    }

    .search_form_container_btn {
        height: 30px;
        width: 250px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 16px;
        color: black;
    }

    .search_form_container_btn:hover {
        transform: scale(1.1);
        transition: 1s;
    }

    .search_form_container_btn:not(:hover) {
        transition: 1s;
    }
    /*конец формы обратной связи  */

    .slider_styles {
        width: 350px;
        height: 187px;
    }

    .mainSlide:hover {
        transform: scale(1);
    }

    .slider_preview_image {
        height: 13px;
        width: 20px;
        margin-top: 5px;
    }

    .object_wrapper {
        margin-top: 30px;
    }

    .object_housename {
        font-size: 30px;
        font-weight: 900;
    }

    .object_description {
        width: 350px;
    }

    .house_info_container {
        font-size: 16px;
    }

    .object_infrastructure {
        width: 350px;
        font-size: 16px;
    }

    .object_map {
        width: 350px;
        height: 550px;
    }
}


/* burger */
.burger {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100vh; */
    height: 220px;
    /* height: 100%; */
    z-index: 1200;
    background-color: #f8f8f8;
    color: #000000;
    padding-left: 20px;
    font-size: 20px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: -110%;
    right: 0;
    top: 0;
    bottom: 0;
    transition: left 1s;
}

.active {
    left: 0;
    z-index: 1200;
}

.burger_btn {
    width: 250px;
    text-align: center;
    height: auto;
    /* background: #e7e7e7; */
    margin-bottom: 20px;
}

.burger_btn:hover {
    background: lightGrey;
}

/* .burger_icon {
    display: block;
    position: absolute;
    right: 20px;
    top: 12px;
    width: 40px;
    height: auto;
    cursor: pointer;
    z-index: 101;
    color: black;
} */
/* end  burger */
